<template>
  <div class="common-layout" v-loading.fullscreen="pageInfo.page_data_loading">
    <el-card class="box-card">
      <template #header>
        <div class="card-header setting-card-header">
          <div class="d-flex justify-content-between">
            <span>Profile</span>
            <el-button type="primary" class="button edit-btn" text
              @click="pageInfo.profile_edit = !pageInfo.profile_edit">
              <span v-if="pageInfo.profile_edit == false">Edit</span>
              <span v-else>Close</span>
            </el-button>
          </div>
        </div>
      </template>
      <el-row :gutter="60">
        <el-col :span="12" class="mt-3">
          <el-row>
            <el-col :span="2" class="d-flex justify-content-center align-items-center">
              <el-icon>
                <font-awesome-icon class="fs-3" :icon="['fas', 'user-circle']" />
              </el-icon>
            </el-col>
            <el-col :span="22">
              <div><b>Account Manager</b></div>
              <el-input v-if="pageInfo.profile_edit" v-model="profile.customer_manager" />
              <span v-else>{{ profile.customer_manager }}</span>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12" class="mt-3">
          <el-row>
            <el-col :span="2" class="d-flex justify-content-center align-items-center">
              <el-icon>
                <font-awesome-icon class="fs-3" :icon="['fas', 'envelope']" />
              </el-icon>
            </el-col>
            <el-col :span="22">
              <div><b>Customer Service Email</b></div>
              <el-input v-if="pageInfo.profile_edit" v-model="profile.customer_email" />
              <span v-else>{{ profile.customer_email }}</span>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12" class="mt-3">
          <el-row>
            <el-col :span="2" class="d-flex justify-content-center align-items-center">
              <el-icon>
                <font-awesome-icon class="fs-3" :icon="['fas', 'warehouse']" />
              </el-icon>
            </el-col>
            <el-col :span="22">
              <div><b>Customer ID</b></div>
              <el-input v-if="pageInfo.profile_edit" v-model="profile.customer_contact_id" />
              <span v-else>{{ profile.customer_contact_id }}</span>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12" class="mt-3">
          <el-row>
            <el-col :span="2" class="d-flex justify-content-center align-items-center">
              <el-icon>
                <font-awesome-icon class="fs-3" :icon="['fas', 'sack-dollar']" />
              </el-icon>
            </el-col>
            <el-col :span="22">
              <div><b>Payment Term</b></div>
              <el-input v-if="pageInfo.profile_edit" v-model="profile.customer_payment_term" />
              <span v-else>{{ profile.customer_payment_term }}</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card mt-4">
      <template #header>
        <div class="card-header setting-card-header">
          <div class="d-flex justify-content-between">
            <span>
              Ads List
              <span class="ms-4 text-active">Total Enabled: {{ adsSummary.enabled_total }}</span>
              <span class="ms-4 text-active">Total Paused: {{ adsSummary.paused_total }}</span>
            </span>

          </div>
        </div>
      </template>
      <!-- Ads List -->
      <TableList :columns="ColumnsAllowanceSettingAds.columns" :data="adsListData" :height="450" :needExport="false">
      </TableList>

      <el-upload class="upload-demo mt-4" :show-file-list="false" drag :action="ads_config.url"
        :file-list="pageInfo.ads_file_list" :data="ads_config.data"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        :on-success="handleUploadAdsSuccess" :on-change="handleChange">
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">
            Only XLS/XLSX files
          </div>
        </template>
      </el-upload>
    </el-card>

    <el-card class="box-card mt-4">
      <template #header>
        <div class="card-header setting-card-header">
          <div class="d-flex justify-content-between">
            <div class="d-flex w-75 align-items-center">
              <span class="me-4">Allowances</span>
              <el-input v-if="pageInfo.allowance_edit" v-model="pageInfo.allowance_tips" />
              <span v-else class="text-danger">
                {{ pageInfo.allowance_tips }}
              </span>

              <div v-if="pageInfo.allowance_edit" class="d-flex w-50 align-items-center">
                <span class="me-2 w-50 text-end">Each order extra fee: </span>
                <el-input-number v-model="pageInfo.allowance_extra_fee" :min="0" :max="100" :precision="4" :step="0.1"
                  controls-position="right" />
              </div>
              <div class="ms-5" v-else-if="pageInfo.allowance_extra_fee && pageInfo.allowance_extra_fee > 0">
                <span class="me-2">Each order extra fee: </span>
                <span class="text-danger">
                  $ {{ pageInfo.allowance_extra_fee }}
                </span>
              </div>
            </div>
            <el-button type="primary" class="button edit-btn" text
              @click="pageInfo.allowance_edit = !pageInfo.allowance_edit">
              <span v-if="pageInfo.allowance_edit == false">Edit</span>
              <span v-else>Close</span>
            </el-button>
          </div>
        </div>
      </template>
      <!-- allowance setting from -->
      <el-form label-width="120px">
        <el-form-item :label="allowance.label" v-for="(allowance, index ) in allowances" :key="index">
          <template #default>
            <el-table :data="allowance.values" border :min-width="550">
              <el-table-column :prop="allowance_column.name" :label="allowance_column.label"
                :width="allowance_column.name == 'Markup' ? '200' : ''"
                v-for="(allowance_column, ii) in allowance.columns">
                <template #header="{ column }">
                  {{ column.label }}
                </template>
                <template #default="{ row, column }">
                  <div v-if="pageInfo.allowance_edit">
                    <el-input v-if="column.property === 'Markup'" v-model="row[column.property]" />

                    <el-input-number class="me-5" v-else="pageInfo.allowance_edit" v-model="row[column.property]"
                      @change="allowance_input(row)" :min="0" :max="100" :precision="4" :step="0.1"
                      controls-position="right" />
                  </div>

                  <span v-else>
                    <span v-if="row[column.property] !== 0">{{ row[column.property] }}</span>
                    <span
                      v-if="column.property !== 'Markup' && isNotEmpty(row[column.property]) && isNumber(row[column.property]) && row[column.property] !== 0">%</span></span>
                </template>
              </el-table-column>

              <el-table-column label="Total" width="180">
                <template #header="{ column }">
                  {{ column.label }}
                </template>
                <template #default="{ row, column }">
                  {{ row.total }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-form-item>
      </el-form>

      <el-row class="justify-content-end">
        <el-button type="primary" @click="handleSaveAllowance" :loading="pageInfo.btn_loading">Save</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script setup name="DashboardSetting">
//引入lodash js工具库
import _ from 'lodash'

import { NumericTothreeDigit, isEmpty, isNotEmpty, isNumber, cloneDeepObject, toNumber, accAdd } from "@/utils/util.js";
import { readExcelFile } from '@/utils/htmlToExcel.js'
import { reactive, ref, toRefs, onMounted, watch, watchEffect, computed } from "vue";
import { useRouter, useRoute, routerKey } from "vue-router";
import { getAllowance, saveAllowance } from "@/api/dashboard";
import { ElMessageBox, ElMessage } from "element-plus";
import moment from "moment";
import TableList from "@/components/TableList";
import { ColumnsAllowanceSettingAds } from "@/config/tableColumns";
import * as XLSX from 'xlsx'

const route = useRoute()
const router = useRouter()

const pageInfo = reactive({
  serial_no: '',
  btn_loading: false,
  page_data_loading: false,
  profile_edit: false,
  allowance_edit: false,
  ads_file_list: [],
  allowance_tips: '',
  allowance_extra_fee: 0
})

let profile_init = {
  customer_manager: '',
  customer_email: '',
  customer_contact_id: '',
  customer_payment_term: ''
}

let allowances_init = [
  {
    key: 'dropship',
    label: 'Dropship',
    columns: [{
      label: 'Markup',
      name: 'Markup',
      is_calculations: false,
    }, {
      label: 'CoOP',
      name: 'CoOP',
      is_calculations: true,
    }, {
      label: 'Damage',
      name: 'Damage',
      is_calculations: true,
    }, {
      label: 'Freight',
      name: 'Freight',
      is_calculations: true,
    }, {
      label: 'Marketing',
      name: 'Marketing',
      is_calculations: true,
    }, {
      label: 'Commission',
      name: 'Commission',
      is_calculations: true,
    }, {
      label: 'Charge Back',
      name: 'Charge Back',
      is_calculations: true,
    }, {
      label: 'Return',
      name: 'Return',
      is_calculations: true,
    }],
    values: [{
      'Markup': '',
      'CoOP': 0,
      'Damage': 0,
      'Freight': 0,
      'Marketing': 0,
      'Commission': 0,
      'Charge Back': 0,
      'Return': 0,
      'total': ''
    }]
  },
  {
    key: 'fulfillment',
    label: 'Fulfillment',
    columns: [{
      label: 'Markup',
      name: 'Markup',
      is_calculations: false,
    }, {
      label: 'CoOP',
      name: 'CoOP',
      is_calculations: true,
    }, {
      label: 'Damage',
      name: 'Damage',
      is_calculations: true,
    }, {
      label: 'Freight',
      name: 'Freight',
      is_calculations: true,
    }, {
      label: 'Marketing',
      name: 'Marketing',
      is_calculations: true,
    }, {
      label: 'Commission',
      name: 'Commission',
      is_calculations: true,
    }, {
      label: 'Charge Back',
      name: 'Charge Back',
      is_calculations: true,
    }, {
      label: 'Return',
      name: 'Return',
      is_calculations: true,
    }],
    values: [{
      'Markup': '',
      'CoOP': 0,
      'Damage': 0,
      'Freight': 0,
      'Marketing': 0,
      'Commission': 0,
      'Charge Back': 0,
      'Return': 0,
      'total': ''
    }]
  },
  {
    key: 'direct',
    label: 'Direct',
    columns: [{
      label: 'Markup',
      name: 'Markup',
      is_calculations: false,
    }, {
      label: 'CoOP',
      name: 'CoOP',
      is_calculations: true,
    }, {
      label: 'Damage',
      name: 'Damage',
      is_calculations: true,
    }, {
      label: 'Freight',
      name: 'Freight',
      is_calculations: true,
    }, {
      label: 'Marketing',
      name: 'Marketing',
      is_calculations: true,
    }, {
      label: 'Commission',
      name: 'Commission',
      is_calculations: true,
    }, {
      label: 'Charge Back',
      name: 'Charge Back',
      is_calculations: true,
    }, {
      label: 'Return',
      name: 'Return',
      is_calculations: true,
    }],
    values: [{
      'Markup': '',
      'CoOP': 0,
      'Damage': 0,
      'Freight': 0,
      'Marketing': 0,
      'Commission': 0,
      'Charge Back': 0,
      'Return': 0,
      'total': ''
    }]
  },
  {
    key: '3pltx',
    label: '3PL TX',
    columns: [{
      label: 'Markup',
      name: 'Markup',
      is_calculations: false,
    }, {
      label: 'CoOP',
      name: 'CoOP',
      is_calculations: true,
    }, {
      label: 'Damage',
      name: 'Damage',
      is_calculations: true,
    }, {
      label: 'Freight',
      name: 'Freight',
      is_calculations: true,
    }, {
      label: 'Marketing',
      name: 'Marketing',
      is_calculations: true,
    }, {
      label: 'Commission',
      name: 'Commission',
      is_calculations: true,
    }, {
      label: 'Charge Back',
      name: 'Charge Back',
      is_calculations: true,
    }, {
      label: 'Return',
      name: 'Return',
      is_calculations: true,
    }],
    values: [{
      'Markup': '',
      'CoOP': 0,
      'Damage': 0,
      'Freight': 0,
      'Marketing': 0,
      'Commission': 0,
      'Charge Back': 0,
      'Return': 0,
      'total': ''
    }]
  },
  {
    key: '3p',
    label: '3P',
    columns: [{
      label: 'Markup',
      name: 'Markup',
      is_calculations: false,
    }, {
      label: 'CoOP',
      name: 'CoOP',
      is_calculations: true,
    }, {
      label: 'Damage',
      name: 'Damage',
      is_calculations: true,
    }, {
      label: 'Freight',
      name: 'Freight',
      is_calculations: true,
    }, {
      label: 'Marketing',
      name: 'Marketing',
      is_calculations: true,
    }, {
      label: 'Commission',
      name: 'Commission',
      is_calculations: true,
    }, {
      label: 'Charge Back',
      name: 'Charge Back',
      is_calculations: true,
    }, {
      label: 'Return',
      name: 'Return',
      is_calculations: true,
    }],
    values: [{
      'Markup': '',
      'CoOP': 0,
      'Damage': 0,
      'Freight': 0,
      'Marketing': 0,
      'Commission': 0,
      'Charge Back': 0,
      'Return': 0,
      'total': ''
    }]
  },
]

let profile = reactive({})
let adsListData = reactive([])
const adsSummary = reactive({ enabled_total: 0, paused_total: 0 })
let allowances = reactive([])

const ads_config = {
  url: '/api/ecomApi/ecommerce/uploadAdsList/',
  data: {
    serial_no: ''
  }
}
adsSummary.paused_total = 0

adsSummary.enabled_total = computed(() => {
  let enabled_total = 0
  adsListData.forEach(item => {
    if (_.toLower(item.state) == "enabled") {
      enabled_total++
    }
  })
  return enabled_total
})

adsSummary.paused_total = computed(() => {
  let paused_total = 0
  adsListData.forEach(item => {
    if (_.toLower(item.state) !== "enabled") {
      paused_total++
    }
  })
  return paused_total
})

watch(() => route.name, (n, o) => {
  if (n !== o && n == "setting") {
    initAllowance()
  }
}, { immediate: false })
watch(() => route.query.id, (n, o) => {
  let route_name = route.name
  if (n !== o && route_name == "setting") {
    initAllowance()
  }
}, { immediate: false })

onMounted(() => {
  initAllowance()
})

function initAllowanceSetting() {
  Object.keys(profile_init).forEach((item) => {
    profile[item] = ''
  })

  allowances.splice(0)
  let allowances_init_slice = cloneDeepObject(allowances_init)
  allowances_init_slice.forEach(item => {
    allowances.push(item)
  })

  //adsListData
  adsListData.splice(0)
}
function allowance_input(row) {
  if (isNotEmpty(row)) {
    let total_allowance = 0

    let abc = []
    Object.keys(row).forEach(key => {
      if (key !== 'Markup' && key !== 'total') {
        let v = parseFloat(row[key])

        total_allowance = accAdd(total_allowance, v)
      }
    })
    row.total = total_allowance + " %"

    //记录操作action
  }
}

function initAllowance() {
  //初始化页面
  initAllowanceSetting()

  const serial_no = route.query.id
  pageInfo.serial_no = serial_no
  ads_config.data.serial_no = serial_no

  let param = {
    serial_no: serial_no
  }
  getAllowance(param).then((res) => {
    //页面配置数据
    let data = res.data
    if (_.isEmpty(data) == false) {
      //设置页面数据
      let d_profile = data.profile

      if (isNotEmpty(d_profile)) {
        profile.customer_contact_id = d_profile.customer_contact_id
        profile.customer_email = d_profile.customer_email
        profile.customer_manager = d_profile.customer_manager
        profile.customer_payment_term = d_profile.customer_payment_term
      }

      //allowance tips
      let allowance_tips = data.allowance_tips
      pageInfo.allowance_tips = allowance_tips

      let allowance_extra_fee = data.allowance_extra_fee
      pageInfo.allowance_extra_fee = allowance_extra_fee

      let d_allowances = data.allowances
      if (isNotEmpty(d_allowances)) {
        setAllowanceSettings(d_allowances)
      }

      //adsListData
      let ads_list = data.adsList
      if (ads_list) {
        ads_list.forEach(item => {
          adsListData.push(item)
        })
      }
    }
  })
}
function setAllowanceSettings(allowanceSettings) {
  //重新赋值对象
  let allowances_init_slice = cloneDeepObject(allowances_init)
  allowances_init_slice.forEach((item_init, index) => {
    let key = item_init.key
    let allowance_item = findAllowanceByKey(allowanceSettings, key)

    if (allowance_item) {
      let allowance_value = allowance_item.values[0]

      //设置values
      let allowance = allowances[index]
      let init_a_value = allowance.values[0]
      Object.keys(init_a_value).forEach(ikey => {
        let a_value = allowance_value[ikey] || ''
        if (isNotEmpty(a_value) && isNumber(a_value)) {
          init_a_value[ikey] = toNumber(a_value)
        } else {
          init_a_value[ikey] = a_value
        }
      })
    }
  })
}
function findAllowanceByKey(allowanceSettings, key) {
  let a_item
  allowanceSettings.forEach((item) => {
    if (item.key === key) {
      a_item = item
      return false
    }
  })

  return a_item
}
//allowance 设置提交
function handleSaveAllowance() {
  //console.log("save");
  pageInfo.btn_loading = true
  //提交数据封装 
  let sv_profile = profile
  let sv_allowances = allowances.slice()

  let calculations_data = {}
  sv_allowances.forEach((item, index) => {
    const key = item.key
    let calculations_total = item.values[0].total

    if (calculations_total) {
      calculations_total = _.toNumber(_.replace(calculations_total, '%', ''))
    } else {
      calculations_total = 0
    }
    calculations_data[key] = calculations_total
  })

  let param = {
    serial_no: pageInfo.serial_no,
    profile: sv_profile,
    allowance_tips: pageInfo.allowance_tips,
    allowance_extra_fee: pageInfo.allowance_extra_fee,
    allowances: sv_allowances,
    allowances_calculations: calculations_data
  }

  //保存方法
  saveAllowance(param)
    .then((res) => {
      pageInfo.profile_edit = false
      pageInfo.allowance_edit = false
      ElMessage({
        message: res.message,
        type: 'success',
        duration: 4 * 1000,
      })
    })
    .finally(() => { pageInfo.btn_loading = false })
}
async function handleUploadAdsSuccess(res) {
  if (res.code !== 20000) {
    ElMessage({
      message: res.message,
      type: 'error',
      duration: 4 * 1000,
    })
  } else {
    let excel_data = res.data.adsList
    if (excel_data) {
      adsListData.splice(0)
      excel_data.forEach(item => {
        adsListData.push(item)
      })
    }
  }
}
function handleChange(file, files) {
}
</script>

<style scoped>
:deep(.el-upload-list) {
  width: 40%
}

:deep(.el-input-number) {
  width: 120px
}

.setting-card-header {
  background-color: #fff;
}
</style>